export const MINDATE = '01/01/1900';
export const MAXDATE = '01/01/2100';
export const monthsArray = [
    { id: 1, value: 'January', dates: Array.from({ length: 31 }, (_, index) => index + 1) },
    { id: 2, value: 'February', dates: Array.from({ length: 28 }, (_, index) => index + 1) },
    { id: 3, value: 'March', dates: Array.from({ length: 31 }, (_, index) => index + 1) },
    { id: 4, value: 'April', dates: Array.from({ length: 30 }, (_, index) => index + 1) },
    { id: 5, value: 'May', dates: Array.from({ length: 31 }, (_, index) => index + 1) },
    { id: 6, value: 'June', dates: Array.from({ length: 30 }, (_, index) => index + 1) },
    { id: 7, value: 'July', dates: Array.from({ length: 31 }, (_, index) => index + 1) },
    { id: 8, value: 'August', dates: Array.from({ length: 31 }, (_, index) => index + 1) },
    { id: 9, value: 'September', dates: Array.from({ length: 30 }, (_, index) => index + 1) },
    { id: 10, value: 'October', dates: Array.from({ length: 31 }, (_, index) => index + 1) },
    { id: 11, value: 'November', dates: Array.from({ length: 30 }, (_, index) => index + 1) },
    { id: 12, value: 'December', dates: Array.from({ length: 31 }, (_, index) => index + 1) },
];
export const weekdaysArray = [
    { id: 0, value: 'Sunday' },
    { id: 1, value: 'Monday' },
    { id: 2, value: 'Tuesday' },
    { id: 3, value: 'Wednesday' },
    { id: 4, value: 'Thursday' },
    { id: 5, value: 'Friday' },
    { id: 6, value: 'Saturday' },
];

export const ROLES = [
    { id: 1, key: "C", desc: "People Manager" },
    { id: 2, key: "U", desc: "User" },
    { id: 3, key: "M", desc: "Manager" },
    { id: 4, key: "A", desc: "Admin" },
    { id: 5, key: "X", desc: "CXO" },
    { id: 6, key: "F", desc: "Finance" },
];