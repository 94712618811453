import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';
import ResourceSchedule from 'src/pages/resourceShedule/ResourceSchedule';
import DeliveryReport from 'src/pages/deliveryReport/DeliveryReport';
import { ROLES } from 'src/utils/CONSTANTS';


const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const role_desc = JSON.parse(localStorage.getItem('user'))?.userRole?.role_desc?.toLowerCase();
  const manager_role = ROLES.find((role) => role.key === 'M').desc.toLowerCase();

  const role = (role_desc === "admin" || role_desc === "finance") ? "pm" : role_desc === manager_role ? "mm" : "em";
  const [routes, setRoutes] = useState([
    {
      path: 'dashboard_em',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <UserDashboard /> },
      ],
    },
    {
      path: 'dashboard_mm',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ManagerDashboard /> },
      ],
    },
    {
      path: 'dashboard_pm',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PMODashboard /> },
      ],
    },
    { path: '/', element: <Navigate to={`/dashboard_${role}`} replace /> },
    {
      path: 'customer',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ViewCustomer /> },
        { path: 'new', element: <CustomerCreate /> },
        { path: 'update/:id', element: <CustomerCreate isEdit={true} /> },
      ],
    },
    {
      path: 'project',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Project /> },
        { path: 'new', element: <ProjectCreate /> },
        { path: 'update/:id', element: <ProjectCreate isEdit={true} /> },
      ],
    },
    {
      path: 'wbs',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Wbs /> },
        { path: 'new', element: <WbsCreate /> },
        { path: 'update/:id', element: <WbsUpdate /> },
      ],
    },
    {
      path: 'resourceUtilization',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ResourceSchedule /> },

      ],
    },
    {
      path: 'ts_utilization_report',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Timesheetutilization /> },

      ],
    },
    {
      path: 'deliveryReport',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <DeliveryReport /> },

      ],
    },
    {
      path: 'approveTimesheet',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Timesheet /> },
        { path: 'approve', element: <ApproveTimesheet /> },
        { path: 'approve/wbs/:wbsId/week/:weekNo/user/:user', element: <ApproveTimesheet isWeekLvl={true} /> },

      ],
    },
    {
      path: 'timesheet',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <TimesheetCreate /> },
      ],
    },
    {
      path: 'resourceallocation',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ResAllocation /> },
      ],
    },
    {
      path: 'jobs',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ViewJobs /> },
        { path: 'new', element: <JobsCreate /> },
        { path: 'edit/:id', element: <JobsCreate isEdit={true} /> },
      ],
    },
    {
      path: 'reports',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Reports /> },
      ],
    },
    {
      path: 'hours',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Hours /> },
      ],
    },
    {
      path: 'user',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <User /> },
        { path: 'new', element: <UserCreate /> },
        { path: 'profile', element: <UserAccount /> },
        { path: 'update/:id', element: <UserCreate isEdit={true} /> },
      ],
    },
    { path: '*', element: <NotFound /> },
  ]);
  let filterValue = [];
  function createRoutesBasedOnRole() {
    const allmenu = JSON.parse(localStorage.getItem('user'))?.allmenu;
    allmenu?.map((e, i) => {
      filterValue.push(e.menu_display_text?.toLowerCase())
    });
    filterValue = [...new Set(filterValue)];
    let filteredRoutes = []
    routes.map((route) => {
      if (filterValue.includes(route.path.toLowerCase())) filteredRoutes.push(route);
    });

    filteredRoutes.push({
      path: 'profile',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <UserAccount /> },
      ],
    });
    filteredRoutes.push({ path: '/', element: <Navigate to={`/dashboard_${role}`} replace /> })
    filteredRoutes.push({ path: '/404', element: <NotFound restricted={true} /> })
    filteredRoutes.push({ path: '*', element: <NotFound /> });
    setRoutes(filteredRoutes);
  };

  useEffect(() => {
    createRoutesBasedOnRole();
  }, [])

  return useRoutes(routes);
}

// IMPORT COMPONENTS

const ViewCustomer = Loadable(lazy(() => import('../pages/customer/ViewCustomer')));
const CustomerCreate = Loadable(lazy(() => import('../pages/customer/CustomerCreate')));

const Project = Loadable(lazy(() => import('../pages/project/Project')));
const ProjectCreate = Loadable(lazy(() => import('../pages/project/ProjectCreate')));

const Wbs = Loadable(lazy(() => import('../pages/wbs/Wbs')));
const WbsCreate = Loadable(lazy(() => import('../pages/wbs/WbsCreate')));
const WbsUpdate = Loadable(lazy(() => import('../pages/wbs/WbsUpdate')));

const Timesheet = Loadable(lazy(() => import('../pages/timesheet/Timesheet')));
const TimesheetCreate = Loadable(lazy(() => import('../pages/timesheet/TimesheetCreate')));
const ApproveTimesheet = Loadable(lazy(() => import('../pages/timesheet/TimesheetAprove')));

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const User = Loadable(lazy(() => import('../pages/user/User')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));

// Admin
const ViewJobs = Loadable(lazy(() => import('../pages/schedular/ViewJobs')));
const JobsCreate = Loadable(lazy(() => import('../pages/schedular/JobsCreate')));

const Reports = Loadable(lazy(() => import('../pages/reports/Reports')));
const Hours = Loadable(lazy(() => import('../pages/reports/Hours')));
const UserDashboard = Loadable(lazy(() => import('../pages/app')));
const PMODashboard = Loadable(lazy(() => import('../pages/app_pm')));
const ManagerDashboard = Loadable(lazy(() => import('../pages/app_mm')));
const ResAllocation = Loadable(lazy(() => import('../pages/reports/ResAllocation')));
const Timesheetutilization = Loadable(lazy(() => import('../pages/dReport/Timesheetutilization')));